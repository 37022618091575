<template>
  <div v-if="serverInfo.name" class="body">
    <b-row class="d-flex justify-content-between header mb-2">
      <b-col xl="8" lg="8" md="6" cols="12">
        <h1 class="serverName text-primary ml-2">
          {{ `${serverInfo.name.toUpperCase()}` }}
        </h1>
      </b-col>
      <b-col xl="4" lg="4" md="6" cols="12" class="wrapper-btns">
        <b-button
          class="mr-2"
          variant="relief-success"
          @click="handleAddOrganization"
        >
          Add Organization
        </b-button>
        <b-button class="" variant="relief-info" @click="handleEdit" style="margin-right: 0;">
          EDIT
        </b-button>
      </b-col>
    </b-row>
    <b-row class="ml-2">
      {{
        `Identifier: ${serverInfo.identifier} - Created at: ${serverInfo.created_at}`
      }}
    </b-row>

    <hr class="my-2" />

    <b-col cols="12">
      <b-row>
        <b-col xl="4" lg="4" md="4" cols="12">
          <b-form-group label="Server Name" label-for="server-name">
            <b-input-group v-if="serverInfo.name" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ServerIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-name"
                placeholder="Server Name"
                :value="`    ${serverInfo.name}`"
                readonly
              />
              <b-input-group-append is-text>
                <b-button class="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Name')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="4" cols="12">
          <b-form-group label="Server Username" label-for="server-username">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="UserIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-username"
                placeholder="Server Username"
                readonly
                :value="`  ${
                  serverInfo.username ? serverInfo.username : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  @click="editServer('Username')"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="4" lg="4" md="4" cols="12">
          <b-form-group label="Password" label-for="server-password">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <!-- <b-input-group-prepend>
                <b-button variant="outline-secondary">
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </b-input-group-prepend> -->
              <b-form-input
                id="server-password"
                placeholder="Server Password"
                readonly
                value=" ************"
              />
              <b-input-group-append is-text>
                <b-button
                  variant="ml-1 btn btn-warning text-white py-25 px-75"
                  @click="editServer('Password')"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="my-1" />
      <b-row>
        <b-col xl="6" lg="6" md="6" cols="12">
          <b-form-group label="Host" label-for="server-host">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="DatabaseIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-host"
                placeholder="Server Host"
                readonly
                :value="`  ${
                  serverInfo.host ? serverInfo.host : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Host')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="6" lg="6" md="6" cols="12">
          <b-form-group label="Type" label-for="server-type">
            <b-input-group v-if="serverInfo.type" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PowerIcon" />
              </b-input-group-prepend>
              <b-form-input
                readonly
                id="server-type"
                placeholder="  Server Type"
                :value="`  ${serverInfo.type}`"
              />
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="3" lg="3" md="5" cols="12">
          <b-form-group label="Status" label-for="server-status">
            <b-input-group v-if="serverInfo.status" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PowerIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-status"
                placeholder="Server Status"
                readonly
                :value="`  ${serverInfo.status}`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Enum_Status')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="3" lg="3" md="5" cols="12">
          <b-form-group readonly label="Weight" label-for="server-weight">
            <b-input-group v-if="serverInfo.weight" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="AnchorIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-weight"
                placeholder="Server Weight"
                readonly
                :value="`   ${serverInfo.weight}`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Weight')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="1" lg="1" md="1" cols="1">
          <b-form-group label="Public" label-for="server-public">
            <b-form-checkbox
              id="server-public"
              :class="`custom-control-success`"
              class="mt-1"
              @change="editIsPublic()"
              v-model="serverInfo.is_public"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="6" lg="6" md="6" cols="12">
          <b-form-group label="Port" label-for="server-port">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SlidersIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-port"
                placeholder="Server Port"
                readonly
                :value="`  ${
                  serverInfo.port ? serverInfo.port : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Port')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="6" lg="6" md="6" cols="12">
          <b-form-group label="Path" label-for="server-path">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="FolderIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="server-path"
                placeholder="Server Path"
                readonly
                :value="`  ${
                  serverInfo.path ? serverInfo.path : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Path')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" ></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="my-1" />
      <b-row>
        <b-col cols="12">
          <b-form-group label="Query" label-for="server-query">
            <b-input-group v-if="serverInfo" class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="ListIcon" />
              </b-input-group-prepend>
              <b-form-textarea
                id="server-query"
                placeholder="Server Query"
                rows="7"
                readonly
                :value="`  ${
                  serverInfo.query ? serverInfo.query : 'Not Informed'
                }`"
              />
              <b-input-group-append is-text>
                <b-button variant="ml-1 btn btn-warning text-white py-25 px-75" @click="editServer('Query')">
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-else>
              <b-skeleton type="input" height="200px"></b-skeleton>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <base-modal />
    <hr class="my-2" />
    <div>
      <h2 class="text-primary">
        Server's Organizations
      </h2>
      <BsTable
        tableName="organization"
        :fields="tableFields"
        :actions="actions"
        :has_paginator="false"
        :show_filter="true"
      />
    </div>
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import moment from "moment";
import { makeToast } from "@/layouts/components/Popups";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BInputGroupAppend,
    BFormTextarea,
    BaseModal,
		BsTable,
    BSkeleton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      serverInfo: [],
			tableFields: custom.tableFields.organization,
      tableItems: [],
      perPage: 10,
      actions: custom.tableActions.organization,
      reloadTable: false,
      totalRows: 0,
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  mounted() {
    this.init()
  },

  methods: {
    init(){
      this.fetchServerById();
		  // this.fetchOrganization();
    },
    fetchServerById() {
      this.$store
        .dispatch("getServerById", this.$route.params)
        .then((response) => {
          console.log(response);
          this.$store.commit("SET_CURRENT_SERVER", response);
          this.serverInfo = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editServer(action) {
      console.log(action);
      this.$store.dispatch("setServerAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editServer${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    editIsPublic() {
      let action = 'editIsServerPublic'
      let modalData = custom.confirmModalStructure.editIsServerPublic;
      this.$bvModal
        .msgBoxConfirm(modalData.message + (this.serverInfo.is_public ? 'public' : 'private') + " ?", modalData.config)
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);
            this.$store
              .dispatch(action, this.serverInfo.is_public)
              .then(() => {
                // this.$store.dispatch(this.$route.meta.renderFuntion, this.$route.params || null).then(response => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(custom.successMessages.isPublicAction);
                // })
              })
              .catch((error) => {
                this.$store.dispatch("setLoadingState", false);
                this.init();

                makeToast(
                  custom.errorMessages[error.code ? error.code : 'isPublicAction']
                );
              });
          } else {
            this.init();
            makeToast(custom.warningMessages[action]);
          }
        });
    },
    handleEdit() {
      this.$router.push({
        name: "edit-server",
        params: { serverId: this.$route.params.serverId },
      });
    },
    handleAddOrganization() {
      this.$store
        .dispatch("setServerAction", "addOrganizationToServer")
        .then(() => {
          this.$store
            .dispatch("setModalObj", "addOrganizationToServer")
            .then(() => {
              this.$store.dispatch("setActiveModal", true);
            })
            .catch(() => {
              console.log(error);
            });
        });
    },
		async fetchOrganization(){
      this.$store.dispatch('getOrganizationByLoggedUser')
        .then((response) => {

          // response.data.items.map(function(value, key){
          //   orgsInfo[key].created_by = (value.organization.created_by.fullname)
          // })
          //*------------------------*// 
          
          console.log(response)

          this.tableItems = response
          this.totalRows  = response.length
        })
        .catch((error) => {
          console.log(error)
        })
    }    
  },
};
</script>

<style lang="scss" scoped>
  .wrapper-btns{
    display: flex;
    justify-content: end;
    @media (max-width: 764px) {
      justify-content: start;
    }
  }
</style>